import { getAuth } from 'firebase/auth';
import Hls from 'hls.js';
import React, { useCallback, forwardRef } from 'react';
import { getIdToken } from 'src/utils/auth';
import { API_URL } from 'src/utils/api';
import Analytics from 'src/utils/analytics';

export type VideoProps = {
  assetId: string;
  className?: string;
};

const Video = forwardRef<HTMLVideoElement, VideoProps>(
  ({ assetId, className }, parentRef) => {
    const hlsUrl = `${API_URL}/video/assets/${encodeURIComponent(assetId)}/${encodeURIComponent(assetId)}.m3u8`,
      nativeUrl = `${API_URL}/video/assets/${encodeURIComponent(assetId)}`;

    const mount = useCallback(
      async (video: HTMLVideoElement) => {
        if (typeof parentRef === 'function') {
          parentRef(video);
        } else if (parentRef) {
          parentRef.current = video;
        }
        if (video) {
          video.onplay = () => {
            Analytics.log('search_videocard_play', { assetId });
          };
        }

        let hls: Hls | null = null;
        if (Hls.isSupported()) {
          try {
            // TODO: should probably just move all this to a context
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
              throw new Error('No user logged in');
            }
            let idToken: string;
            try {
              idToken = await getIdToken(user);
            } catch (error) {
              // TODO(@cwegrzyn): this seems weird as getIdToken should force a refresh if one is needed?
              if (
                error instanceof Error &&
                error.message.includes('auth/quota-exceeded')
              ) {
                console.warn(
                  'Token refresh quota exceeded. Using existing token.'
                );
                idToken = await user.getIdToken(false);
              } else {
                throw error;
              }
            }
            console.log('Token obtained:', idToken.slice(0, 10) + '...');

            hls = new Hls({
              xhrSetup: (xhr: XMLHttpRequest) => {
                xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
              },
              maxBufferLength: 5,
              maxMaxBufferLength: 30,
              startFragPrefetch: false,
            });

            hls.loadSource(hlsUrl);
            hls.attachMedia(video);

            hls.on(Hls.Events.ERROR, (event, data) => {
              console.error('HLS error:', event, data);
            });
          } catch (error) {
            console.error('Error initializing HLS:', error);
          }
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          console.warn(
            'HLS.js not supported, falling back to native playback. Authentication may fail.'
          );
          video.src = nativeUrl;
        } else {
          console.error('HLS is not supported in this browser.');
        }
        return () => {
          if (hls) {
            hls.destroy();
          }
        };
      },
      [hlsUrl, nativeUrl]
    );

    return <video className={className} ref={mount} controls />;
  }
);

export default Video;
