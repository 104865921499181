import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const TermsAcknowledgement: React.FC = () => {
  const cookieName = 'machine-terms-agreement';
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies();
  const postHog = usePostHog();

  useEffect(() => {
    if (!cookies[cookieName]) {
      setIsDisplayed(true);
    }
  }, []);

  const onAgreeClick = () => {
    postHog.capture('terms__privacy_acknowledged');
    setCookie(cookieName, true);
    setIsDisplayed(false);
  };

  return (
    isDisplayed && (
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.8)] z-40">
        <div className="mt-[30vh] mx-auto w-96 bg-neutral-60 rounded p-6 font-source_serif_pro z-50">
          <p className="mb-6">
            Please agree to the&nbsp;
            <a
              className="text-green-100 underline"
              href="/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>
            &nbsp; and&nbsp;
            <a
              className="text-green-100 underline"
              href="/terms"
              target="_blank"
            >
              Terms of Use
            </a>
            .
          </p>
          <button
            onClick={onAgreeClick}
            className="transition-colors font-inter text-white rounded-md bg-green-100 py-2 px-3 hover:bg-green-100/80"
          >
            Agree
          </button>
        </div>
      </div>
    )
  );
};

export default TermsAcknowledgement;
