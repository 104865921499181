import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, lazy, Suspense } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import EnforceLogin from './components/EnforceLogin';
import PageContainer from './components/PageContainer';
import { AuthProvider } from './contexts/authProvider';
import { EditorConfigProvider } from './contexts/editorConfigProvider';
import { NotificationsProvider } from './contexts/notificationsProvider';
const Root = lazy(() => import('./routes/root'));
const Zeitgeist = lazy(() => import('./routes/zeitgeist'));
const ClusterDebug = lazy(() => import('./routes/cluster_debug'));
const Contact = lazy(() => import('./routes/contact'));
const Notifications = lazy(() => import('./routes/notifications'));
const HowTo = lazy(() => import('./routes/how-to'));
const Terms = lazy(() => import('./routes/terms'));

// Todo: figure out why lazy fails with privacy info
import { PrivacyInfo } from './routes';
import Analytics from './utils/analytics';

const App: React.FC = () => {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
  });
  /**
   *
   * Take care of analytics regarding logins etc here
   *
   */
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        Analytics.setUser(user);
        Analytics.setUserProperties({
          account_created_at: user.metadata.creationTime,
          last_sign_in_at: user.metadata.lastSignInTime,
        });
        Analytics.log('login', {
          method: user.providerData[0]?.providerId || 'unknown',
        });
      } else {
        // User is signed out
        Analytics.setUser(null);
        Analytics.log('logout');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthProvider>
      <PostHogProvider client={posthog}>
        <NotificationsProvider>
          <EditorConfigProvider>
            <Router>
              <EnforceLogin>
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <PageContainer>
                            <Root />
                        </PageContainer>
                      }
                    />

                    <Route
                      path="/search/:searchQuery"
                      element={
                        <PageContainer>
                            <Root />
                        </PageContainer>
                      }
                    />

                    <Route
                      path="/zeitgeist"
                      element={
                        <PageContainer>
                          <Zeitgeist />
                        </PageContainer>
                      }
                    />

                    <Route
                      path="/cluster-debug/:runId/:clusterId"
                      element={<ClusterDebug />}
                    />

                    <Route
                      path="/contact"
                      element={
                        <PageContainer>
                          <Contact />
                        </PageContainer>
                      }
                    />

                    <Route
                      path="/notifications"
                      element={
                        <PageContainer>
                          <Notifications />
                        </PageContainer>
                      }
                    />

                    <Route
                      path="/how-to"
                      element={
                        <PageContainer>
                          <HowTo />
                        </PageContainer>
                      }
                    />
                    <Route
                      path="/privacy"
                      element={
                        <PageContainer>
                          <PrivacyInfo />
                        </PageContainer>
                      }
                    />
                    <Route
                      path="/terms"
                      element={
                        <PageContainer>
                          <Terms />
                        </PageContainer>
                      }
                    />
                  </Routes>
                </Suspense>
              </EnforceLogin>
            </Router>
          </EditorConfigProvider>
        </NotificationsProvider>
      </PostHogProvider>
    </AuthProvider>
  );
};

export default App;
