import React from 'react';

const TranscriptHighlights: React.FC = () => {
  return (
    <div className="block font-inter text-xs text-neutral-190 flex flex-wrap gap-1 mb-2.5">
      <span className="shrink-0 font-bold">Transcript highlights: </span>
      <span className="shrink-0 underline text-neutral-170 group/transcript cursor-pointer">
        And that little girl was me
        {/* Tooltip actions */}
        <div className="hidden absolute bottom-full w-auto rounded-md bg-neutral-190/85 overflow-hidden group-hover/transcript:block">
          <ul>
            <li className="block font-inter text-xs text-white py-1.5 px-2 hover:bg-white/20">
              Jump to clip
            </li>
            <li className="block font-inter text-xs text-white py-1.5 px-2 hover:bg-white/20">
              Download clip
            </li>
            <li className="block font-inter text-xs text-white py-1.5 px-2 hover:bg-white/20">
              Show transcript
            </li>
          </ul>
        </div>
        {/* Tooltip actions */}
      </span>
      <span className="shrink-0 underline text-neutral-170">
        They did it, Joe
      </span>
      <span className="shrink-0 underline text-neutral-170">
        And yes, maybe that isn’t normal for America
      </span>
      <span className="shrink-0 underline text-neutral-170">
        This fight is unlike...
      </span>
    </div>
  );
};

export default TranscriptHighlights;
