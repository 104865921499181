import React, { memo } from 'react';
import VideoCard from './VideoCard';
import Asset from 'src/interfaces/Asset';

interface VideoListProps {
  assets: Asset[];
  forceExpanded?: boolean;
}

const MemoizedVideoCard = memo(VideoCard);

const VideoList: React.FC<VideoListProps> = ({ assets, forceExpanded }) => {
  return (
    <>
      {assets.map((asset, index) => {
        return (
          <MemoizedVideoCard
            key={asset.id}
            assetId={asset.id}
            metadata={asset}
            forceExpanded={forceExpanded}
            right={!!(index % 2)}
          />
        );
      })}
    </>
  );
};

export default VideoList;
