import React from 'react';
import Utterance from 'src/interfaces/UtteranceWithId';
import { useEditorConfigContext } from 'src/contexts/editorConfigProvider';
import secondsToMinutes from 'src/utils/secondsToMinutes';

interface TranscriptProps {
  utterances: Utterance[];
  jumpToClip: (start_time: number) => void;
  searchQuery: string;
}

const Transcript: React.FC<TranscriptProps> = ({
  utterances,
  jumpToClip,
  searchQuery,
}) => {
  const clickHandler = (start_time: number) => {
      jumpToClip(start_time);
    },
    { selectedUtterances, toggleUtterance } = useEditorConfigContext();

  return utterances.map((utterance, i) => {
    const checked = !!selectedUtterances.find((u) => u.id === utterance.id);
    const regex = new RegExp(searchQuery, 'g');
    const shouldDisplay = searchQuery === '' || regex.test(utterance.text);
    return (
      <div key={i} className={shouldDisplay ? 'block' : 'hidden'}>
        <div className="flex w-full px-5 py-2 border-b border-neutral-110 font-inter text-sm text-neutral-190 hover:bg-neutral-50">
          <div className="w-9 shrink-0">
            <input
              checked={checked}
              onChange={toggleUtterance.bind(this, utterance)}
              className="w-4 h-4 rounded border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
              type="checkbox"
            />
          </div>
          <div className="shrink-0 group/clip relative w-28 cursor-pointer hover:text-green-100">
            <span>
              {secondsToMinutes(utterance.start_time)} -{' '}
              {secondsToMinutes(utterance.end_time)}
            </span>
            {/* Tooltip actions */}
            <div className="hidden absolute top-0 w-auto rounded-md bg-neutral-190/85 overflow-hidden group-hover/clip:block">
              <ul>
                <li
                  onClick={clickHandler.bind(this, utterance.start_time)}
                  className="block font-inter text-xs text-white py-1.5 px-2 hover:bg-white/20"
                >
                  Jump to clip
                </li>
              </ul>
            </div>
            {/* Tooltip actions */}
          </div>
          <div className="grow">{utterance.text}</div>
        </div>
      </div>
    );
  });
};

export default Transcript;
