import React from 'react';

interface ContactProps {
  // Define your props here
}

const Contact: React.FC<ContactProps> = () => {
  return (
    <div>
      <div className="block font-source_serif_pro text-xl text-neutral-170 mb-10">
        Contact us
      </div>
      <div className="flex justify-between gap-x-32">
        <div className="w-full max-w-96 shrink">
          <span className="block font-source_serif_pro text-[40px] leading-[48px] text-neutral-170 mb-3">
            We’d love to hear from you
          </span>
          <p className="font-inter text-lg text-neutral-180">
            Whether it’s feedback, a question about The Machine or bug
            reporting, you can use this form to get in touch. We’ll get back to
            you as soon as possible.
          </p>
        </div>
        <div className="grow w-full max-w-[484px] shrink-0">
          <div className="flex gap-x-5 justify-between mb-8">
            <label className="w-1/2" htmlFor="first_name">
              <span className="block font-inter text-base text-neutral-190 mb-2">
                First name*
              </span>
              <input
                className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                type="text"
                id="first_name"
                placeholder="John"
              />
            </label>

            <label className="w-1/2" htmlFor="last_name">
              <span className="block font-inter text-base text-neutral-190 mb-2">
                First name*
              </span>
              <input
                className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                type="text"
                id="last_name"
                placeholder="Dow"
              />
            </label>
          </div>

          <div className="block mb-8">
            <label className="w-full" htmlFor="email">
              <span className="block font-inter text-base text-neutral-190 mb-2">
                Email*
              </span>
              <input
                className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100 disabled:bg-neutral-40"
                type="email"
                id="email"
                placeholder="johndoe@gmail.com"
                disabled
              />
            </label>
          </div>

          <div className="block mb-8">
            <label className="w-full" htmlFor="topic">
              <span className="block font-inter text-base text-neutral-190 mb-2">
                Topic
              </span>
              <select
                className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                id="topic"
              >
                <option value="" selected>
                  Select a topic
                </option>
                <option value="">Option 1</option>
                <option value="">Option 2</option>
                <option value="">Option 3</option>
              </select>
            </label>
          </div>

          <div className="block mb-8">
            <label className="w-full" htmlFor="message">
              <span className="block font-inter text-base text-neutral-190 mb-2">
                Message*
              </span>
              <textarea
                className="transition-all w-full h-28 bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                id="message"
                placeholder="Tell us what’s on your mind"
              ></textarea>
            </label>
          </div>

          <div className="block mb-8">
            <label className="w-full">
              <span className="block font-inter text-base text-neutral-190 mb-2">
                Attachments*
              </span>
              <div className="transition-all w-full h-28 bg-white py-3 px-4 border border-dashed border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100">
                Drag & drop files here or Browse files
              </div>
            </label>
          </div>

          <div className="block mb-8">
            <button className="transition-colors flex justify-between items-center gap-x-3 rounded-[10px] bg-green-100 py-3 px-4 hover:bg-green-120">
              <span className="font-inter text-base text-white">Submit</span>{' '}
              <i className="block w-5 h-5 bg-ico-arrow-right bg-center bg-no-repeat bg-contain"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
