import Cluster, { Run } from '@/interfaces/Cluster';
import * as d3 from 'd3';
import { useEffect, useState } from 'react';

interface ArticleGraph {
  cluster: Cluster;
  run?: Run;
}

interface DataPoint {
  date: Date;
  count: number;
}

const ArticleGraph: React.FC<ArticleGraph> = ({ cluster, run }) => {
  const chartId = `chart-${cluster.id}`;
  const chartSelector = `#${chartId}`;
  const width = 400;
  const height = 200;
  const padding = 48;
  const [chartData] = useState(buildChartData());

  useEffect(() => {
    drawChart();
  }, [chartData]);

  function getStartOfDateUTC(utcTime = 0): Date {
    const newDate = new Date(0);
    newDate.setUTCSeconds(utcTime || 0);
    newDate.setSeconds(0);
    newDate.setMinutes(0);
    newDate.setHours(0);

    return newDate;
  }

  // Find each unique date and count the articles published on that date
  function buildChartData(): DataPoint[] {
    let startingMapData = Array(run?.window).fill(0);
    const oneDay = 60 * 60 * 24;

    // Set date buckets for articles
    startingMapData = startingMapData.map((value: number, index: number) => {
      const date = getStartOfDateUTC(
        run ? run.timestamp - index * oneDay : 0
      ).toDateString();

      return [date, value];
    });

    const data = new Map<string, number>(startingMapData);

    // Sort articles into 'day' buckets
    cluster.articles.forEach((article) => {
      const pubDate = getStartOfDateUTC(article.pub_date).toDateString();
      const count = data.get(pubDate);

      if (count !== undefined) {
        data.set(pubDate, count + 1);
      } else {
        data.set(pubDate, 1);
      }
    });

    // Sort and convert to DataPoint
    return Array.from(data)
      .sort((pointA, pointB) =>
        new Date(pointA[0]) > new Date(pointB[0]) ? 1 : -1
      )
      .map((entry) => {
        return { count: entry[1], date: new Date(entry[0]) } as DataPoint;
      });
  }

  const drawChart = () => {
    const windowDays = 60 * 60 * 24 * (run ? run.window : 7);
    const minusOneDay = run ? run?.timestamp - 60 * 60 * 24 : 0;

    // The clusters don't include the current day so we move the end date back by 1
    const endDate = getStartOfDateUTC(minusOneDay);
    const startDate = getStartOfDateUTC(
      run?.timestamp ? run?.timestamp - windowDays : 0
    );

    const chartRef = d3.select(chartSelector);
    const maxYVal = d3.max(chartData, (datapoint) => datapoint.count) || height;

    const xScale = d3
      .scaleTime()
      .domain([startDate, endDate])
      .range([0 + padding, width - padding]);

    const yScale = d3
      .scaleLinear([0, height])
      .domain([0, maxYVal])
      .range([height - padding, 0 + padding / 2]);

    drawBars(chartRef, xScale, yScale);
    drawAxes(chartRef, xScale, yScale, maxYVal, startDate, endDate);
  };

  const drawAxes = (
    chartRef: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>,
    xScale: d3.ScaleTime<number, number, never>,
    yScale: d3.ScaleLinear<number, number, never>,
    maxYVal: number,
    startDate: Date,
    endDate: Date
  ) => {
    const axisColor = '#d4d2cd';
    const labelColor = '#2f2f2e';
    const dateFormat = d3.utcFormat('%b %-d');

    const xAxis = d3
      .axisBottom(xScale)
      .tickValues([startDate, endDate])
      .tickFormat((date) => dateFormat(new Date(date.valueOf())))
      .tickSizeOuter(0)
      .tickPadding(16);

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues([maxYVal])
      .tickSizeOuter(0)
      .tickPadding(8);

    // Remove existing axes if they exist
    d3.selectAll(`${chartSelector} .xAxis, ${chartSelector} .yAxis`).remove();

    chartRef
      .append('g')
      .attr('transform', `translate(0, ${height - padding})`)
      .attr('class', 'xAxis')
      .attr('stroke', labelColor)
      .attr('stroke-width', 2)
      .call(xAxis);

    chartRef
      .append('g')
      .attr('transform', `translate(${padding}, 0)`)
      .attr('class', 'yAxis')
      .attr('stroke', labelColor)
      .attr('stroke-width', 2)
      .call(yAxis);

    chartRef.selectAll(`${chartSelector} text`).attr('stroke-width', 1);
    d3.selectAll(`${chartSelector} path.domain`).attr('stroke', axisColor);
  };

  const drawBars = (
    chartRef: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>,
    xScale: d3.ScaleTime<number, number, never>,
    yScale: d3.ScaleLinear<number, number, never>
  ) => {
    const windowSize = run ? run.window : 7;
    const width = 400 * (1 / (windowSize + 2));

    chartRef
      .append('g')
      .attr('fill', '#4DA389')
      .selectAll()
      .data(chartData)
      .join('rect')
      .attr('x', (datapoint) => xScale(datapoint.date))
      .attr('y', (datapoint) => yScale(datapoint.count))
      .attr('height', (datapoint) => yScale(0) - yScale(datapoint.count))
      .attr('width', width);
  };

  return <svg id={chartId} viewBox={`0 0 ${width} ${height}`}></svg>;
};

export default ArticleGraph;
