import React, {
  createContext,
  ReactNode,
  useEffect,
  useContext,
  useState,
} from 'react';
import { GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { auth } from '../firebase';
import { API_URL } from '../utils/api';
import MachineApi from '../utils/machineApi';

type AuthContextType = {
  user: User | null;
  loading: boolean;
  login: () => Promise<void>;
  machineApi: MachineApi;
};

const initialContext: AuthContextType = {
  user: null,
  loading: true,
  login: async () => {},
  machineApi: new MachineApi(null),
};

const AuthContext = createContext<AuthContextType>(initialContext);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null),
    nullMachineApi = new MachineApi(null),
    [machineApi, setMachineApi] = useState<MachineApi>(nullMachineApi),
    [loading, setLoading] = useState(true);

  const login = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setMachineApi(new MachineApi(currentUser));
        await fetch(`${API_URL}/auth/login`, {
          method: 'POST',
          body: JSON.stringify({ id_token: await currentUser.getIdToken() }),
          headers: { 'Content-Type': 'application/json' },
          // NOTE(@cwegrzyn): Needed to process the cookie from the API, at least on localhost
          // given different ports, but not sure if this is the correct approach or if it's
          // needed in deployment
          credentials: 'include',
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value: AuthContextType = {
    user,
    loading,
    login,
    machineApi,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
