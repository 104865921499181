import Spinner from '@/components/Spinner';
import TermsAcknowledgement from '@/components/TermsAcknowledgement';
import Cluster, {
  Article,
  Bucket,
  CLUSTER_SORT,
  Run,
} from '@/interfaces/Cluster';
import '@/styles/zeitgeist.scss';
import { getAuth } from '@firebase/auth';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import ClusterCard from 'src/components/zeitgeist/ClusterCard';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';
import { db } from '../firebase';

interface ZProps {}

const defaultBuckets = (): Bucket[] => [
  { name: 'US Politics', clusters: [] },
  { name: 'US National Events', clusters: [] },
  { name: 'World News', clusters: [] },
  { name: 'Other', clusters: [] },
];

const Zeitgeist: React.FC<ZProps> = () => {
  const auth = getAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [allBuckets, setAllBuckets] = useState<Bucket[]>(defaultBuckets());
  const [allRuns, setAllRuns] = useState<Run[]>();
  const [clusterSort, setClusterSort] = useState<CLUSTER_SORT>(
    CLUSTER_SORT.ARTICLE_COUNT
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openCardIndex, setOpenCardIndex] = useState<number>();
  const [selectedBucket, setSelectedBucket] = useState<string>();
  const [selectedRun, setSelectedRun] = useState<Run>();
  const clusterCollectionName = FIRESTORE_COLLECTION_NAMES.CLUSTERS;
  const runsCollectionName = FIRESTORE_COLLECTION_NAMES.RUNS;

  useEffect(() => {
    if (!allRuns?.length) {
      fetchRuns();
    }
  }, []);

  useEffect(() => {
    sortClusters(allBuckets);
  }, [clusterSort]);

  useEffect(() => {
    if (selectedRun) {
      setIsLoading(true);
      fetchClusters();
    }
  }, [selectedRun]);

  const divideClustersIntoBuckets = (clusters: Cluster[]) => {
    const buckets = defaultBuckets();

    function findOrCreateBucketWithName(name: string): Bucket {
      const foundBucket = buckets.find((bucket) => bucket.name === name);

      if (foundBucket) {
        return foundBucket;
      } else {
        const newBucket: Bucket = { name: name, clusters: [] };
        buckets.push(newBucket);

        return newBucket;
      }
    }

    const otherBucket = buckets[3];

    clusters.forEach((cluster) => {
      const bucketName = cluster.interpretation.bucket;

      if (bucketName) {
        findOrCreateBucketWithName(bucketName).clusters.push(cluster);
      } else {
        otherBucket.clusters.push(cluster);
      }
    });

    sortClusters(buckets);
    setSelectedBucket('US Politics');
  };

  const fetchClusters = async () => {
    if (!userExists()) {
      return;
    }

    const _clusters: Cluster[] = [];

    const clustersRef = collection(
      db,
      `${runsCollectionName}/${selectedRun?.id}/${clusterCollectionName}`
    );

    const clusterQuery = query(clustersRef, orderBy('article_count', 'desc'));
    const clustersSnapshot = await getDocs(clusterQuery);

    clustersSnapshot.forEach((clusterDocument) => {
      const cluster = {
        ...clusterDocument.data(),
        id: clusterDocument.id,
      } as Cluster;
      filterArticles(cluster);
      _clusters.push(cluster);
    });

    divideClustersIntoBuckets(_clusters);
    setIsLoading(false);
  };

  const fetchRuns = async () => {
    if (!userExists()) {
      return;
    }

    if (!runsCollectionName) {
      console.error('No collection name provided');
      return;
    }

    // Get runs
    const runsRef = collection(db, runsCollectionName);
    const runsQuery = query(runsRef, orderBy('timestamp', 'desc'), limit(3));
    const runsSnapshot = await getDocs(runsQuery);

    // Get clusters for runs
    const runsList = runsSnapshot.docs
      .map((runDoc) => ({ ...runDoc.data(), id: runDoc.id }) as Run)
      .sort((runA: Run, runB: Run) => (runA.window > runB.window ? 1 : -1))
      .reverse();

    setAllRuns(runsList);
    setRunSelection(runsList);
  };

  const filterArticles = (cluster: Cluster) => {
    const articleSet = new Set();
    const newArticles: Article[] = [];

    cluster.articles.forEach((article: Article) => {
      if (!articleSet.has(article.url)) {
        articleSet.add(article.url);
        newArticles.push(article);
      }
    });

    cluster.articles = newArticles;
  };

  const findBucket = (name: string, buckets: Bucket[]): Bucket => {
    return (
      buckets.find((bucket: Bucket) => name === bucket.name) || allBuckets[3]
    );
  };

  const setRunSelection = (runsList: Run[]) => {
    let runSelection: Run;
    const requestedRun = searchParams.get('run');

    if (requestedRun) {
      const selectedRun = runsList.find((run: Run) => run.id === requestedRun);

      if (selectedRun) {
        runSelection = selectedRun;
      } else {
        runSelection = runsList[0];
      }
    } else {
      runSelection = runsList[0];
    }

    setSelectedRun(runSelection);
  };

  const sortClusters = (buckets: Bucket[]) => {
    const newBuckets = [...buckets];
    let sortFn: (a: Cluster, b: Cluster) => number;

    switch (clusterSort) {
      case CLUSTER_SORT.SCORE:
        sortFn = (clusterA: Cluster, clusterB: Cluster) =>
          clusterA.interpretation.grade > clusterB.interpretation.grade
            ? -1
            : 1;
        break;
      case CLUSTER_SORT.ARTICLE_COUNT:
      default:
        sortFn = (clusterA: Cluster, clusterB: Cluster) =>
          clusterA.article_count > clusterB.article_count ? -1 : 1;
        break;
    }

    buckets.forEach((bucket: Bucket) => {
      bucket.clusters.sort(sortFn);
    });

    setAllBuckets(newBuckets);
  };

  const userExists = () => {
    const user = auth.currentUser;

    if (!user) {
      console.error('No user available');
    }

    return user;
  };

  // Interface
  const clusterCards = findBucket(
    selectedBucket || 'Other',
    allBuckets
  ).clusters.map((cluster, index) => (
    <ClusterCard
      key={cluster.id}
      index={index}
      isOpen={openCardIndex === index}
      cluster={cluster}
      run={selectedRun}
      setOpenIndex={setOpenCardIndex}
    />
  ));

  const loadingScreen = (
    <div className="loading">
      <Spinner />
    </div>
  );

  const getTooltipAnchor = (text: string) => {
    return (
      <div
        className="info bg-ico-info"
        data-tooltip-id="tooltip"
        data-tooltip-content={text}
      ></div>
    );
  };

  return (
    <div className="zeitgeist_wrapper">
      <p className="top_header">Recent media trends and analysis</p>
      <p className="subheader">
        Discover recent trending topics, complete with sourced articles and
        relevant video assets from our continuously updated library.
      </p>

      <div className="filter_wrapper">
        <select
          name="run_selection"
          className="bg-ico-sort"
          id="run_selection"
          disabled={isLoading}
          value={selectedRun?.id}
          onChange={(event) => {
            setSelectedRun(
              allRuns?.find((run: Run) => run.id === event.target.value)
            );
            setSearchParams(new URLSearchParams(`run=${event.target.value}`));
          }}
        >
          {allRuns?.map((run) => (
            <option key={run.id} value={run.id}>
              {run.window === 1 ? 'Last 24 hours' : `Last ${run.window} days`}
            </option>
          ))}
        </select>

        <select
          name="bucket_selection"
          className="bg-ico-sort"
          id="bucket_selection"
          disabled={isLoading}
          value={selectedBucket}
          onChange={(event) => {
            setSelectedBucket(event.target.value);
          }}
        >
          {allBuckets.map((bucket) => (
            <option key={bucket.name} value={bucket.name}>
              {bucket.name}
            </option>
          ))}
        </select>

        <select
          onChange={(event) => {
            setClusterSort(parseInt(event.target.value) as CLUSTER_SORT);
          }}
          disabled={isLoading}
          className="recency bg-ico-sort"
        >
          <option value={CLUSTER_SORT.ARTICLE_COUNT}>By article count</option>
          <option value={CLUSTER_SORT.SCORE}>By usefulness</option>
        </select>
      </div>

      <div className="results">
        <div className="headers">
          <div className="subject">Subject</div>
          <div className="topics">Topics</div>
          <div className="articles">
            Articles
            {getTooltipAnchor(
              'The more articles there are about a subject, the higher it will be listed.'
            )}
          </div>
          <div className="videos">
            {selectedRun?.window !== 1 &&
              `Articles in the Past ${selectedRun?.window} days`}
            {/* {getTooltipAnchor(
              'Video files from our database relevant to the topics.'
            )} */}
          </div>

          <Tooltip arrowColor="transparent" id="tooltip" place="bottom-start" />
        </div>
        {isLoading ? loadingScreen : clusterCards}
      </div>
      <TermsAcknowledgement />
    </div>
  );
};

export default Zeitgeist;
