import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import defaults from 'src/defaults';

interface AdvancedSettingsProps {
  open: boolean;
  searchType: string;
  setSearchType: Dispatch<SetStateAction<string>>;
  localK: number;
  setK: Dispatch<SetStateAction<number>>;
}

const AdvancedSettings: React.FC<AdvancedSettingsProps> = ({
  open,
  searchType,
  setSearchType,
  localK,
  setK,
}) => {
  return (
    <div
      className={`transition-all flex w-full justify-between items-center mb-2 ${open ? 'h-[34px]' : 'h-0 overflow-hidden'}`}
    >
      <div className="flex w-auto rounded-md border border-green-120 overflow-hidden">
        <span
          onClick={setSearchType.bind(this, 'mmr')}
          className={classNames(
            'transition-colors px-3 py-2 font-inter font-medium text-xs cursor-pointer hover:bg-green-100 hover:text-white',
            {
              'bg-green-100 text-white': searchType === 'mmr',
              'text-neutral-190': searchType !== 'mmr',
            }
          )}
        >
          Maximum Marginal Relevance
        </span>
        <span
          onClick={setSearchType.bind(this, 'similarity')}
          className={classNames(
            'transition-colors px-3 py-2 font-inter font-medium text-xs cursor-pointer hover:bg-green-100 hover:text-white',
            {
              'bg-green-100 text-white': searchType === 'similarity',
              'text-neutral-190': searchType !== 'similarity',
            }
          )}
        >
          Similarity
        </span>
      </div>

      <div className="group cursor-pointer text-neutral-170 relative">
        <div className="flex items-center justify-between gap-x-3">
          <span className="font-inter text-base">Max results: {localK}</span>
          <i className="block w-4 h-4 bg-ico-arrow-down bg-center bg-no-repeat"></i>
        </div>

        {/* dropdown */}
        <div className="absolute hidden z-50 rounded-b-md top-full w-full right-0 bg-neutral-60 border border-neutral-110 border-t-0 group-hover:block">
          <ul className="w-full">
            {defaults.search.localKOptions.map((num) => (
              <li
                key={num}
                onClick={setK.bind(this, num)}
                className="transition-colors block font-inter text-base text-neutral-140 py-3 pl-4 pr-11 border-t border-neutral-110 hover:bg-neutral-80 hover:text-neutral-170"
              >
                {num}
              </li>
            ))}
          </ul>
        </div>
        {/* //dropdown */}
      </div>
    </div>
  );
};

export default AdvancedSettings;
