export const DEFAULT_FIRESTORE_COLLECTION_NAMES = {
  ASSETS: process.env.ASSETS_FIRESTORE_COLLECTION || 'assets',
  CLUSTERS: process.env.CLUSTERS_FIRESTORE_COLLECTION || 'clusters',
  EDIT_REQUESTS:
    process.env.EDIT_REQUESTS_FIRESTORE_COLLECTION || 'edit_requests',
  HUMAN_ANNOTATIONS:
    process.env.HUMAN_ANNOTATIONS_FIRESTORE_COLLECTION || 'human_annotations',
  MEMBERSHIPS: process.env.MEMBERSHIPS_FIRESTORE_COLLECTION || 'memberships',
  ORGANIZATIONS:
    process.env.ORGANIZATIONS_FIRESTORE_COLLECTION || 'organizations',
  RUNS: process.env.REACT_APP_RUNS_FIRESTORE_COLLECTION || 'cluster_runs',
  SCORES: process.env.SCORES_FIRESTORE_COLLECTION || 'scores-prod',
};

export const FIRESTORE_COLLECTION_NAMES = {
  ...DEFAULT_FIRESTORE_COLLECTION_NAMES,
};

window.FIRESTORE_COLLECTION_NAMES = FIRESTORE_COLLECTION_NAMES;

if(window.location.search.includes('FIRESTORE_COLLECTION_NAMES')) {
  const urlParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlParams) {
    // if the key is FIRESTORE_COLLECTION_NAMES
    if(key.startsWith('FIRESTORE_COLLECTION_NAMES')) {
      // parse the value and assign it to FIRESTORE_COLLECTION_NAMES
      FIRESTORE_COLLECTION_NAMES[key.split('.')[1]] = value;
    }
  }
}

console.log('****** Default collection names in use. Inspect window global FIRESTORE_COLLECTION_NAMES for details.');
console.log('****** FIRESTORE_COLLECTION_NAMES is a live variable and can be modified in the console.');
console.log('****** You can also set a query parameter in the URL to override the default collection names.');
console.log('****** Example: ?FIRESTORE_COLLECTION_NAMES.ASSETS=custom_assets_collection_name');

const defaultConfig = {
  editConfig: {
    targetResolution: {
      width: {
        min: 480,
        max: 3840,
        step: 1,
      },
      height: {
        min: 360,
        max: 2160,
        step: 1,
      },
    },
    clipBuffer: {
      min: 0,
      max: 1,
      step: 0.1,
    },
  },
  FIRESTORE_COLLECTION_NAMES,
  search: {
    type: 'similarity',
    localK: 250,
    localKOptions: [10, 50, 100, 250, 500],
  },
};


export default defaultConfig;
