import React from 'react';

interface VibesProps {
  vibes: string[] | undefined;
  topics: string[] | undefined;
}

const Vibes: React.FC<VibesProps> = ({ vibes, topics }) => {
  const pills: string[] = [];

  if (vibes && vibes.length > 0) {
    const vibesToAdd = vibes.slice(0, 3);
    vibesToAdd.forEach((vibe) => {
      const capitalizedVibe = vibe.charAt(0).toUpperCase() + vibe.slice(1);
      pills.push(capitalizedVibe);
    });
  }

  if (topics && topics.length > 0) {
    const topicsToAdd = topics.slice(0, 3);
    topicsToAdd.forEach((topic) => {
      if (pills.includes(topic)) return;
      pills.push(topic);
    });
  }

  return (
    <div className="flex gap-2 flex-wrap">
      {pills.map((pill) => (
        <span
          key={pill}
          className="transition-colors py-1 px-2 rounded-md border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-xs tracking-wide"
        >
          {pill}
        </span>
      ))}
    </div>
  );
};

export default Vibes;
