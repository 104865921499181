import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/authProvider';
import { getAuth, signOut } from 'firebase/auth';
import { useNotificationsContext } from 'src/contexts/notificationsProvider';
import FLAGS from '../featureFlags';
import classNames from 'classnames';

const signOutWithFirebase = () => {
  const auth = getAuth();
  signOut(auth).catch((error) => {
    // An error happened.
    console.error('Sign out error:', error);
  });
};

const Sidebar: React.FC = () => {
  const { user } = useAuth(),
    { notifications } = useNotificationsContext(),
    unreadNotifications = notifications.filter((i) => !i.read),
    currentRoute = window.location.pathname,
    classForRoute = (route: string) =>
      classNames({
        'relative transition-colors flex flex-col items-center gap-x-4 rounded-md p-3 hover:bg-neutral-110 xl:flex-row':
          true,
        'bg-neutral-110': currentRoute === route,
      });

  return (
    <div className="relative w-36 min-h-screen bg-neutral-100 flex flex-col pt-[89px] px-6 xl:px-[40px] xl:w-96">
      <span className="block font-inter text-neutral-180 text-[22px] leading-8 uppercase tracking-wider mb-11">
        <span className="hidden xl:block">The Machine</span>
        <span className="block text-center text-4xl xl:hidden">M</span>
      </span>

      <nav className="block">
        <ul>
          <li className="mb-2">
            <Link to="/" className={classForRoute('/')}>
              <i className="block w-6 h-6 bg-ico-home bg-no-repeat bg-center"></i>
              <span className="block font-source_serif_pro text-center text-base text-neutral-180 xl:text-lg xl:leading-6 xl:text-left">
                Home
              </span>
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/zeitgeist" className={classForRoute('/zeitgeist')}>
              <i className="block w-6 h-6 bg-ico-zeitgeist bg-no-repeat bg-center"></i>
              <span className="block font-source_serif_pro text-center text-base text-neutral-180 xl:text-lg xl:leading-6 xl:text-left">
                Zeitgeist
              </span>
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/how-to" className={classForRoute('/how-to')}>
              <i className="block w-6 h-6 bg-ico-how-to-use bg-no-repeat bg-center"></i>
              <span className="block font-source_serif_pro text-center text-base text-neutral-180 xl:text-lg xl:leading-6 xl:text-left">
                How to use
              </span>
            </Link>
          </li>
          {false && (
            <>
              <li className="mb-2">
                <Link
                  to="/influencer-agents"
                  className={classForRoute('/influencer-agents')}
                >
                  <i className="block w-6 h-6 bg-ico-influencer bg-no-repeat bg-center"></i>
                  <span className="block font-source_serif_pro text-center text-base text-neutral-180 xl:text-lg xl:leading-6 xl:text-left">
                    Influencer agents
                  </span>
                </Link>
              </li>
            </>
          )}
          <li>
            <Link
              to="/notifications"
              className={classForRoute('/notifications')}
            >
              <i className="block w-6 h-6 bg-ico-notifications bg-no-repeat bg-center"></i>
              <span className="block font-source_serif_pro text-center text-base text-neutral-180 xl:text-lg xl:leading-6 xl:text-left">
                Notifications
              </span>
              {unreadNotifications.length > 0 && (
                <span className="absolute -top-1 right-3 block w-6 h-6 rounded-full bg-green-100 text-center font-inter text-white text-[11px] leading-[24px] ml-auto xl:relative xl:top-auto xl:right-auto">
                  {unreadNotifications.length}
                </span>
              )}
            </Link>
          </li>
          <li className="mb-2">
            <a
              className={classForRoute('no-match')}
              href="https://machine-experimental.qsf.dev"
              target="_blank"
            >
              <i className="block w-6 h-6 bg-ico-link-out bg-no-repeat bg-center"></i>
              <span className="block font-source_serif_pro text-center text-base text-neutral-180 xl:text-lg xl:leading-6 xl:text-left">
              Experimental Features  
              </span>
            </a>
          </li>
          <li className="mb-2">
            <a
              className={classForRoute('no-match')}
              href="https://app.blueroseresearch.org/predictive-scoring"
              target="_blank"
            >
              <i className="block w-6 h-6 bg-ico-link-out bg-no-repeat bg-center"></i>
              <span className="block font-source_serif_pro text-center text-base text-neutral-180 xl:text-lg xl:leading-6 xl:text-left">
                Blue Rose Research
              </span>
            </a>
          </li>
        </ul>
      </nav>
      {user && (
        <div className="fixed bottom-12">
          <div className="group/account relative flex flex-nowrap gap-x-4 px-3 py-3 items-center rounded-md xl:border xl:border-neutral-110 xl:bg-neutral-60">
            <div className="hidden absolute w-[calc(100%+2px)] bottom-[calc(100%-4px)] -left-[1px] border border-neutral-110 bg-neutral-40 rounded-t-md overflow-hidden group-hover/account:block">
              <ul>
                {FLAGS.CONTACT_US && (
                  <li className="flex w-full justify-between items-center py-3 px-3 cursor-pointer hover:bg-neutral-80">
                    <Link to="/contact">Contact us</Link>
                  </li>
                )}
                <li
                  className="flex w-full justify-between items-center py-3 px-3 cursor-pointer hover:bg-neutral-80"
                  onClick={signOutWithFirebase}
                >
                  <span>Log out</span>
                </li>
              </ul>
            </div>

            {user.photoURL && (
              <span
                style={{ backgroundImage: `url(${user.photoURL})` }}
                className="block rounded-full h-10 w-10 bg-cover bg-center bg-no-repeat shrink-0"
              />
            )}
            <div className="block font-inter text-base text-neutral-190">
              <span className="hidden mb-1 xl:block">{user.email}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
