/* eslint-disable @typescript-eslint/no-explicit-any */

import { analytics } from '../firebase';
import { setUserId, logEvent, setUserProperties } from 'firebase/analytics';
import { User } from 'firebase/auth';

class Analytics {
  user: User | null;

  constructor() {
    this.user = null;
    if (this.shouldLogEvent()) {
      console.log(`[ANALYTICS] initialized`);
    } else {
      console.log(`[ANALYTICS] in dev mode, no events will be logged`);
    }
  }

  log(eventName: string, eventParams?: { [key: string]: any }) {
    if (!this.shouldLogEvent()) {
      console.log(`[ANALYTICS] would logEvent ${eventName}`, eventParams);
      return;
    }
    if (eventParams) {
      logEvent(analytics, eventName, eventParams);
    } else {
      logEvent(analytics, eventName);
    }
  }

  isDev() {
    return process.env.NODE_ENV === 'development';
  }

  isTeamEmail() {
    if (!this.user) {
      return false;
    }
    const disallowedDomains = ['@3-tone.com', '@oestech.io'];
    for (const domain of disallowedDomains) {
      if (this.user.email?.includes(domain)) {
        return true;
      }
    }
    const disallowedAddresses = [
      'tudortru@gmail.com',
      'brandon.silverman@gmail.com',
      'stafford@4d53.io',
      'matthew.stafford@gmail.com',
      'russ.fagaly@gmail.com',
    ];
    for (const address of disallowedAddresses) {
      if (this.user.email === address) {
        return true;
      }
    }
    return false;
  }

  shouldLogEvent() {
    return !this.isDev() && !this.isTeamEmail();
  }

  setUser(user: User | null) {
    this.user = user;
    const shouldLog = this.shouldLogEvent();
    if (user) {
      if (shouldLog) {
        this.setUserId(user.uid);
        this.setUserProperties({
          account_created_at: user.metadata.creationTime,
          last_sign_in_at: user.metadata.lastSignInTime,
        });
      } else {
        console.log(`[ANALYTICS] would setUserId ${user.uid}`);
      }
    } else {
      if (shouldLog) {
        this.setUserId(null);
      } else {
        console.log(`[ANALYTICS] would setUserId null`);
      }
    }
  }

  setUserId(userId: string | null) {
    setUserId(analytics, userId);
  }

  setUserProperties(properties: { [key: string]: any }) {
    if (this.shouldLogEvent()) {
      setUserProperties(analytics, properties);
    } else {
      console.log(`[ANALYTICS] would setUserProperties`, properties);
    }
  }
}

export default new Analytics();
