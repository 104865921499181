export default interface Cluster {
  article_count: number;
  articles: Article[];
  assets: Asset[];
  clustering_algorithm: string;
  id: string;
  interpretation: Interpretation;
  recent_article_count: number;
  topics: string[];
}

export interface Run {
  id: string;
  window: number;
  timestamp: number;
}

export interface Article {
  fullArticle?: unknown;
  headline: string;
  id: string;
  pub_date: number;
  score: number;
  source: string;
  url: string;
}

export interface Asset {
  date_added: number;
  description: string;
  id: string;
  score: number;
}

export interface Interpretation {
  bucket: string;
  explanation: string;
  grade: number;
  narrative: string;
  query: string;
  subject: string;
}

export interface Bucket {
  name: string;
  clusters: Cluster[];
}

export enum CLUSTER_SORT {
  ARTICLE_COUNT = 1,
  SCORE,
}
