import { Asset } from '@/interfaces/Cluster';
import '@/styles/components/videoTile.scss';

interface VideoTile {
  video: Asset;
}

const VideoTile: React.FC<VideoTile> = ({ video }) => {
  const videoDate = new Date(0);
  videoDate.setUTCSeconds(video.date_added);

  return (
    <div className="video_tile">
      {/* <img
        width="170"
        height="106"
        src="https://videopromotion.club/assets/images/default-video-thumbnail.jpg"
        alt="video_thumbnail"
      /> */}

      <div className="content">
        <a href={`/search/${video.id}`} target="_blank">
          <div className="description">{video.description}</div>
          <div className="row">
            <div className="added">{videoDate.toLocaleDateString('en-US')}</div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default VideoTile;
