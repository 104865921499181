import React from 'react';
import FLAGS from '../featureFlags';

import { useAuth } from '../contexts/authProvider';

const Login: React.FC = () => {
  const { login } = useAuth();

  return (
    <div className="flex h-screen antialiased">
      <div className="w-[60%] flex items-center justify-center bg-neutral-100">
        <div className="w-full max-w-[500px]">
          <h1 className="font-source_serif_pro text-[73px] leading-[88px] text-neutral-170 mb-5">
            Welcome to The Machine
          </h1>
        </div>
      </div>
      <div className="w-[40%] pl-[104px] relative items-center bg-neutral-60">
        <div className="absolute w-full max-w-[360px] top-1/2 -translate-y-1/2">
          <p className="font-inter text-xl tracking-wide mb-4">
            Get started with your Google account
          </p>
          <button
            className="w-full flex items-center justify-center gap-x-5 h-[66px] rounded-[10px] bg-white border border-black/40"
            onClick={login}
          >
            <i className="block w-6 h-6 bg-ico-google"></i>{' '}
            <span className="text-xl font-noto font-semibold">
              Log in with Google
            </span>
          </button>
        </div>
        {FLAGS.CONTACT_US && (
          <div className="absolute w-full max-w-[360px] bottom-[90px]">
            <p className="font-inter text-lg tracking-wide mb-4">
              Have a question?{' '}
              <a href="#" className="text-green-100 hover:underline">
                Contact us
              </a>
            </p>
            {FLAGS.TERMS_AND_CONDITIONS && (
              <p className="font-inter text-lg tracking-wide">
                By continuing you’re agreeing to our{' '}
                <a href="#" className="text-green-100 hover:underline">
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a href="#" className="text-green-100 hover:underline">
                  Privacy Policy
                </a>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
