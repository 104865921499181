import React from 'react';
import ScoreData from 'src/interfaces/ScoreData';

interface BlueRoseGaugeProps {
    score_data: ScoreData;
};

const BlueRoseGauge: React.FC<BlueRoseGaugeProps> = ({score_data}) => {
  if(score_data?.percentiles?.everyone) {
    const score = score_data.percentiles.everyone,
          first_quartile_width = Math.min(25, score) / 25 * 100,
          second_quartile_width = Math.max(0, Math.min(25, score - 25)) / 25 * 100,
          third_quartile_width = Math.max(0, Math.min(25, score - 50)) / 25 * 100,
          fourth_quartile_width = Math.max(0, Math.min(25, score - 75)) / 25 * 100;

    return <div className="block my-3">
            <span className="block font-inter text-sm text-neutral-170 mb-2">Blue Rose Score: {score_data.percentiles.everyone}/100</span>
            <ul className="grid grid-cols-4 gap-x-2">
              <li className="h-1 rounded-sm overflow-hidden bg-neutral-100"><span style={{width: `${first_quartile_width}%`}} className="block h-1 bg-green-100"></span></li>
              <li className="h-1 rounded-sm overflow-hidden bg-neutral-100"><span style={{width: `${second_quartile_width}%`}} className="block h-1 bg-green-100"></span></li>
              <li className="h-1 rounded-sm overflow-hidden bg-neutral-100"><span style={{width: `${third_quartile_width}%`}} className="block h-1 bg-green-100"></span></li>
              <li className="h-1 rounded-sm overflow-hidden bg-neutral-100"><span style={{width: `${fourth_quartile_width}%`}} className="block h-1 bg-green-100"></span></li>
            </ul>
          </div>
  }
};

export default BlueRoseGauge;
